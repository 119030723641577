.App {
  text-align: center;
}

.App-header {
  background-color: #f5f7fa; /* Changed to a lighter color for a modern look */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-button {
  background-color: #ff6f61; /* More vibrant button color */
  border: none;
  color: white;
  padding: 15px 30px; /* Increased padding for better touch target */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 20px; /* More rounded corners */
  transition: background-color 0.3s ease;
}

.App-button:hover {
  background-color: #ff3c2f; /* Slightly darker color on hover for better UX */
}

body {
  background-color: #e0f7fa; /* Fun background color */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
